<style>
.el-icon-info{color:#999; font-size:32px; margin-right:5px;}
.line{display:flex; flex-wrap: nowrap;}
.role{ max-width:150px; }
.subject{font-size:12px; color:#999;}
.value{font-size:12px; color:#666; }
.el-cascader{width:170px;}
.keyword{width:200px;}
.red{color:red;}
.block td{background-color:#e6e6e6 !important}
.btn_location{font-size:20px; color:#099831; padding-right:5px; cursor:pointer;}
.article .edit_dialog{ height:90vh; margin:5vh auto !important }
.edit_dialog .big-hint{font-size:17px; color:#777; font-weight: bold}
.edit_dialog .el-row:nth-of-type(n+2){margin-top:10px;}
.edit_dialog .label{font-size:15px; color:#909399;}
.edit_dialog .btn-add{display:flex; justify-content: center; align-items: center; font-size:22px; color:#aaa;}
.mini-table td{padding:3px 0 !important;}
.img-bar{display:flex; align-items: stretch; overflow-x: auto; flex-wrap: nowrap;}
.img-box{flex:0 0 auto; position: relative; width:60px; height:60px; margin-right:5px; overflow: hidden; border:solid 1px #ccc; border-radius:5px; cursor:pointer;}
.mask{position: absolute; top:0; right:0; padding:2px 4px; font-size:16px; z-index:2; background:rgba(0,0,0,0.7); text-align: center; color:#fff; border-radius:0 0 0 5px;}
.img{width:100%; height:100%; object-fit: cover; }
.el-image-viewer__wrapper{z-index:2021 !important;}
.flex-grow{flex:1 1 auto; display: flex; align-items: stretch; margin-top: 10px !important;}
.sort-input .el-input__inner{text-align: center;  padding:0;}
</style>
<template>
    <div class="component article">
        <!-- 编辑框 -->
        <el-dialog width="860px" custom-class="edit_dialog" :title="data_for_edit.id>0 ? '编辑资讯' : '添加资讯'" v-model="show_edit" :close-on-click-modal="false">

            <el-row class="label">
                <el-col :span="2" class="red">所属分类*</el-col>
                <el-col :span="22"><el-cascader style="width:100%;" placeholder="选择资讯所属分类" v-model="data_for_edit.chn" clearable size="small" ref="chns1" @change="this.$refs.chns1.popperVisible = false" :props="{checkStrictly: true, expandTrigger: 'hover', emitPath:true, value:'id', label:'name', lazy:true, lazyLoad:this.chn_lazyload}"></el-cascader>
                </el-col>
            </el-row>
            <el-row class="label">
                <el-col :span="2" class="red">资讯标题*</el-col>
                <el-col :span="22">
                    <el-input v-model="data_for_edit.title" placeholder="请输入资讯标题" maxlength="256" show-word-limit  size="small"></el-input>
                </el-col>
            </el-row>
            <el-row class="label">
                <el-col :span="2">封面图片</el-col>
                <el-col :span="22" class="img-bar">
                    <div v-for="(url,idx) in data_for_edit.cover_imgs" :key="idx" class="img-box">
                        <div class="mask"><i class="el-icon-delete" @click="click_edit_photo_del(idx)"></i></div>
                        <el-image class="img" :src="gd.base_url+url" fit="cover" :preview-src-list="preview_imgs"></el-image>
                    </div>
                    <div v-if="data_for_edit.cover_imgs.length < 3" class="img-box btn-add"  @click="click_edit_photo_add"><i class="el-icon-picture-outline"></i></div>
                </el-col>
            </el-row>
            <el-row class="label">
                <el-col :span="2"><span class="label">内容摘要</span></el-col>
                <el-col :span="22"><el-input v-model="data_for_edit.abstract" type="textarea" rows="3" maxlength="100" show-word-limit placeholder="纯文字" size="mini"></el-input></el-col>
            </el-row>
            <el-row class="label flex-grow">
                <CKEditor :show="show_edit" :content="def_content_for_ckeditor" ref="ckeditor"></CKEditor>
            </el-row>
            <template #footer>
                
                <el-button type="primary" @click="click_edit_done">确 定</el-button>
                <el-button @click="show_edit = false">关 闭</el-button>
            </template>
        </el-dialog>
        <!-- 编辑框结束 -->

        <div class="title-panel">
            <el-row>
                <el-col :span="7">
                    <i class="el-icon-info"></i>
                    <div class="inline"><div class="label">资讯管理</div><div class="descript">管理资讯</div></div>
                </el-col>
                <el-col :span="17" class="btn-col">
                    <el-button type="success" plain icon="el-icon-circle-plus-outline" @click="click_add">添加资讯</el-button>
                    <el-button type="primary" plain icon="el-icon-refresh" @click="load_list">刷新数据</el-button>
                </el-col>
            </el-row>
        </div>
        <!-- 筛选条 -->
        <el-row style="display:flex; align-items: center; background: #f9f9f9; padding:5px 5px;">
            <el-cascader style="width: 200px" placeholder="过滤分类" v-model="chn" :options="chns" :props="chn_props" clearable ref="chns" @change="chns_change" size="small"></el-cascader>
            <div>&nbsp;</div>
            <el-select class="role" v-model="block" placeholder="上架状态" clearable size="small">
                <el-option label="上架" value="0"></el-option>
                <el-option label="下架" value="1"></el-option>
            </el-select>
            
            <div>&nbsp;</div>
            <el-date-picker style="width:230px" v-model="date" type="daterange" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="x" size="small"></el-date-picker>
            <div>&nbsp;</div>
            <el-input style="width:200px" class="keyword" size="small" v-model="key" placeholder="搜索标题" clearable></el-input> 
            <div style="flex-grow:1">&nbsp;</div>
            <el-pagination background layout="total, prev, pager, next" :page-size="size" :current-page="page" :total="total" @current-change="page_change"></el-pagination>
        </el-row>
        <!-- 筛选条结束 -->
        <div class="table-box" id="table-box">
            <el-table :max-height="table_height" :data="list" :stripe="true" border :row-class-name="tableRowClassName">
                <el-table-column prop="chn_name" label="所属分类" width="100" align="center" :overflow-show-tips="true"></el-table-column>
                <el-table-column prop="title" label="资讯标题" width="450" :overflow-show-tips="true"></el-table-column>
                <el-table-column prop="click" label="浏览量" width="80" align="center"></el-table-column>
                <el-table-column prop="updated_at" label="编辑时间" width="180" align="center" :formatter="format_timestamp"></el-table-column>
                <el-table-column prop="create_name" label="编辑人" width="150" align="center"></el-table-column>
                
                <el-table-column fixed="right" min-width="150" label="操作">
                    <template #default="scope">
                        <el-button v-if="scope.row.block==0" @click="click_block(scope.$index)" type="text" size="small">下架</el-button>
                        <el-button v-else @click="click_block(scope.$index)" type="text" size="small">上架</el-button>
                        <el-button @click="click_edit(scope.row)" type="text" size="small">编辑</el-button>
                        <el-button @click="click_del(scope.$index)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>      
        </div>
    </div>
</template>
<script>
import {inject} from 'vue'
import format_timestamp from "../plugins/utils.js"
import CKEditor from "../components/CKEditor.vue"
export default {
    components:{ CKEditor },
    setup:function(){
    	const gd = inject('gd');
    	return {gd};
    },//setup
    data:function(){
        return {
            key_input_delay:null,
            chns:[],
            chn_cache:{},
            chn:'',
            block:'',
            date:null,
            key:'',
            page:1,
            size:10,
            total:0,
            list:[],
            show_edit:false,
            data_for_edit:{},
            def_content_for_ckeditor:'',
            table_height:800,
            editor_dom:'content',
            chn_props:{ checkStrictly: true, expandTrigger: 'hover', emitPath:false, value:'id', label:'name', lazy:true, lazyLoad:this.chn_lazyload },
        }
    },//data end
    mounted:function(){
        this.$nextTick(()=>{ this.table_height = document.getElementById('table-box').offsetHeight -12; });
        this.init();
    },//mounted end
    computed:{
        preview_imgs:function(){
            let res = [];
            for(let i=0; i<this.data_for_edit.cover_imgs.length; i++){
                res.push(this.gd.base_url+this.data_for_edit.cover_imgs[i]);
            }
            return res;
        },//preview_imgs
    },
    watch:{
        page:function(){ this.load_list(); },//page
        chn:function(){ this.page=1; this.load_list(); },//chn
        block:function(){ this.page=1; this.load_list(); },//block
        date:function(){ this.page=1; this.load_list(); },//date
        key:function(){
            clearTimeout(this.key_input_delay);
            this.key_input_delay = setTimeout(()=>{
                this.page=1; this.load_list();
            }, 800);
        },//key
    },
    methods:{
        init: function(){
            this.load_list();
        },//init
        chn_lazyload:function(node,resolve){
            this.axios.post("/api/article/chn_list",{ pid:node.value ? node.value : 0}).then(ret=>{
                if(!ret.ok){this.$message.error(ret.msg); return;}
                let data = [];
                ret.data.map(item=>{
                    data.push({id:item.id, name:item.name, leaf:item.hasChildren == 0});
                    this.chn_cache[item.id] = item.name;
                });
                resolve(data);
            });
            //console.log(node)
        },//chn_lazyload
        chns_change:function(chn){
            if(chn){ this.$refs.chns.togglePopperVisible(); }
        },//chns_change
        load_list:function(){
            let para = {chn:this.chn, page:this.page, size:this.size, key:this.key, block:this.block, withContent:1};
            if(this.date != null && this.date.length == 2){ para.date = this.date; }
            this.axios.post("/api/article/list",para).then(ret=>{
                if(!ret.ok){this.$message.error(ret.msg); return;}
                this.total = ret.data.total;
                this.list = ret.data.list;
            });
        },//load_list
        tableRowClassName:function({row}){
            //console.log(row);
            return row.block == 1 ? 'block' : '';
        },//tableRowClassName
        format_timestamp:function(row){
            return format_timestamp(row.updated_at, true);
        },//format_timestamp
        page_change:function(page){ this.page = page; },//page_change
        click_block:function(idx){
            this.axios.post("/api/article/block",{ id:this.list[idx].id}).then(ret=>{
                if(!ret.ok){this.$message.error(ret.msg); return;}
                this.$message.success('操作成功');
                this.list[idx].block = 1 - this.list[idx].block;
            });
        },//click_top
        click_del:function(idx){
            this.$confirm('此操作将永久删除该资讯, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.axios.post("/api/article/del",{ id:this.list[idx].id}).then(ret=>{
                    if(!ret.ok){this.$message.error(ret.msg); return;}
                    this.$message.success('操作成功');
                    this.list.splice(idx,1);
                }).catch((err) => { console.error(err); });
            }).catch((e) => { console.error(e); });
        },//click_del end
        click_add:function(){
            this.show_edit = true;
            this.data_for_edit = {
                id:0,
                chn:[],
                title:'',
                cover_imgs:[],
                abstract:'',
                content:'',
            };
            this.def_content_for_ckeditor = this.data_for_edit.content;
            //Vue.nextTick(()=>{  this.init_ckeditor(this.editor_dom,this.data_for_edit.content); });
        },//click_add
        click_edit:function(row){
            //console.log(row);
            this.data_for_edit = JSON.parse(JSON.stringify(row));
            this.data_for_edit.chn = this.data_for_edit.chn_path;
            this.def_content_for_ckeditor = this.data_for_edit.content;
            this.show_edit = true;
            //Vue.nextTick(()=>{ this.init_ckeditor(this.editor_dom,this.data_for_edit.content); });
        },//click_edit end
        click_edit_done:function(){
            if(this.data_for_edit.chn.length < 1){ this.$message.error('请选择所属栏目'); return; }
            if(this.data_for_edit.title == ''){ this.$message.error('请输入资讯标题'); return; }
            let ck_data = this.$refs.ckeditor.getContent();
            if(this.data_for_edit.abstract =='' && ck_data.txt == ''){ this.$message.error('请输入内容摘要'); return; }
            if(ck_data.html == ''){ this.$message.error('请输入资讯内容'); return; }
            this.data_for_edit.content = ck_data.html;
            if( this.data_for_edit.abstract == '' ){ this.data_for_edit.abstract = ck_data.txt; }

            if(this.data_for_edit.cover_imgs.length < 1){
                let reg = /<img.+?src="(.+?)".+?\/>/ig; 
                let i = 0;
                while(i<3){
                    i++;
                    let res = reg.exec(this.data_for_edit.content);
                    if(!res || res.length != 2){break;}
                    this.data_for_edit.cover_imgs.push(res[1]);
                }
            }
         
            this.axios.post("/api/article/edit",this.data_for_edit).then(ret=>{
                if(!ret.ok){this.$message.error(ret.msg); return;}
                this.$message.success('操作成功');
                this.show_edit = false;
                if(this.data_for_edit.id == 0){ this.page = 1; }
                this.load_list();
            });
        },//click_edit_done
        click_edit_photo_add:function(){
            let input = document.createElement("INPUT");
            input.type = "file";
            input.addEventListener('change',e=>{
                if(e.srcElement.files.length == 0){this.remove_input(input); return;}
                let file = e.srcElement.files[0];
                let formData = new FormData();
                formData.append('upload',file,file.name);
                const config = {
                    headers: { "Content-Type": "multipart/form-data;boundary="+new Date().getTime() }
                };
                this.axios.post("/api/util/image",formData,config).then(ret=>{
                    this.remove_input(input);
                    if(!ret.ok){this.$message.error(ret.msg); return;}
                    this.data_for_edit.cover_imgs.push(ret.data.url);
                    //app.$forceUpdate();
                }).catch(error=>{
                    this.remove_input(input);
                    console.log(error);
                    this.$message.error(error.msg); return;
                });
            });
            document.body.appendChild(input);
            input.click();
        },//click_edit_photo_add
        remove_input:function(dom){
            if(!document.body.contains(dom)){return;}
            document.body.removeChild(dom);
        },//remove_input
        click_edit_photo_del:function(idx){
            this.data_for_edit.cover_imgs.splice(idx,1);
        },//click_edit_photo_del
    },//methods end
};
</script>